import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const targetTime = new Date(); // Initialize with current date and time
  targetTime.setHours(16); // Set the target hour (in 24-hour format)
  targetTime.setMinutes(55); // Set the target minute

  const [countdown, setCountdown] = useState(calculateCountdown());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateCountdown() {
    const now = new Date();
    const timeDifference = targetTime - now;

    if (timeDifference < 0) {
      // If target time has already passed, calculate for next day
      targetTime.setDate(targetTime.getDate() + 1);
      return calculateCountdown(); // Recurse until next day's target time
    }

    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  }

  return (
    <div className='timer'>
        <span>{countdown.hours}</span> : <span>{countdown.minutes}</span> : <span>{countdown.seconds}</span>
    </div>
  );
};

export default CountdownTimer;